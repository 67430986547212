<template>
  <div class="pb-20">
    <div class="grid grid-cols-1 md:grid-cols-2 mb-10">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Limit Increase Requests</h2>
        <router-link :to="{ name: 'users-new' }" class="text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>
    </div>

    <nav class="border border-b-2 border-blue-200 px-8 pt-1">
      <ul class="flex flex-wrap items-center w-full">
        <li
          class="-mb-2px pr-20 relative"
          v-for="(t, index) in tabs"
          :key="index"
        >
          <a
            href="#!"
            class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{
              'border-blue-500 text-blue-500 hover:text-blue-500':
                tab === t.name
            }"
            @click.prevent="setTab(t.name)"
          >
            <span class="mr-2">{{ t.title }}</span>
          </a>
          <span
            class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2"
          />
        </li>
      </ul>
    </nav>

    <component :is="tab" />
  </div>
</template>

<script>
export default {
  components: {
    'limit-requests': () => import('./Requests'),
    'reviewed-requests': () => import('./Reviewed'),
    'self-employed-requests': () => import('./SelfEmployed'),
    'employed-requests': () => import('./Employed')
  },
  data() {
    return {
      tab: 'limit-requests'
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'limit-requests',
          title: 'Limit Increase Requests'
        },
        {
          name: 'self-employed-requests',
          title: 'Self Employed Limit Requests'
        },
        {
          name: 'employed-requests',
          title: 'Employed Limit Requests'
        },
        {
          name: 'reviewed-requests',
          title: 'Reviewed Limit Requests'
        }
      ];
    }
  },
  mounted() {
    this.tab = this.$route.query.tab || this.tab;
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, tab }
      });
    }
  }
};
</script>
